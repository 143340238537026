import React from 'react'
import { Link } from 'react-router-dom'
import 'font-awesome/css/font-awesome.min.css';
import useSWR from 'swr'
import { endpoints } from '../../constants/global-constans';
import { fetcher } from '../../utils/helperFunctions';
import parse from 'html-react-parser';

function ContactText() {
  const {data} = useSWR(endpoints.landingPage.contactSection,fetcher)

  const title = data && data.data.attributes.title
  const description = data && data.data.attributes.description
  const email = data && data.data.attributes.email
  const mobileNubmer = data && data.data.attributes.mobileNumber
  const social_handles = data && data.data.attributes.social_handles.data

  return (
    <>
    <div className="contact-text">
        <h2>{parse(title??'')}</h2>
        <p>{parse(description??'')}</p>

        <div className="email-box">
            <h5>Write to us</h5>
            <p>{parse(email??'')}</p>
        </div>
       
        <div className="phone-box">
        <h5>Call us</h5>
        <p>{parse(mobileNubmer??'')}</p>
        </div>

        <div className="socal-box">
        <h5>Follow us on</h5>
        {
          social_handles && social_handles.map((item,index)=>{
            return(
              <Link to={item.attributes.url} key={item.id}><i className={`fa fa-${item.attributes.label}`}></i></Link>
            )
          })
        }
        </div>

    </div>
    </>
    
  )
}

export default ContactText