import React from 'react'
import 'bootstrap/dist/css/bootstrap.css';
import './whoarewe.css';
import parse from 'html-react-parser';

function WhoareweTitle({title}) {
  return (
    <>
      <div className="whoarewe-title text-center mb-5">
        <h2>{parse(title??'')}</h2>
      </div>
    </>
  )
}

export default WhoareweTitle
