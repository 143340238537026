import React from "react";
import "bootstrap/dist/css/bootstrap.css";
import "./aboutTestimonial.css";
// Import css files
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import parse from 'html-react-parser';

function AboutTestimonialSlider({clients}) {
  console.log(clients)
  var settings = {
    className: "center",
    centerMode: true,
    dots: true,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 10000,
    pauseOnHover: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 786,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
      <div className="about-testimonial-section text-center mt-4 mb-4">
        <Slider {...settings}>
          {clients?.map((list) => (
            <>

            <div key={list.id}>
              <div className="about-testimonial-box">
                <img src={list.attributes.image.data.attributes.url} alt="" />
                <h3>{parse(list.attributes.name??'')}</h3>
                <p>
                  {parse(list.attributes.message??'')}
                </p>
              </div>
            </div>
               
              </>

          ))}
        </Slider>
      </div>
    </>
  );
}

export default AboutTestimonialSlider;
