import React from 'react'
import 'bootstrap/dist/css/bootstrap.css';
import './testimonial.css';
import "react-bootstrap-carousel/dist/react-bootstrap-carousel.css";
import Carousel from 'react-bootstrap/Carousel';
import parse from 'html-react-parser';

function testimonialSlider({team_members}) {
  return (
    <>
       <Carousel slide={false}>
         {team_members?.map((member) => {
            return (
            <Carousel.Item key={member.id}>
               <Carousel.Caption>
                  <img src={member?.attributes?.image?.data?.attributes?.url} alt=''/>
                  <div>
                     {parse(member?.attributes?.name??'')}
                  </div>
                  <div>
                     {parse(member?.attributes?.intro??'')}
                  </div>
               </Carousel.Caption>
            </Carousel.Item>
         )})}
      </Carousel>
    </>
  )
}
export default testimonialSlider
