import React from 'react'
import 'bootstrap/dist/css/bootstrap.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import './service.css';
import ServicesTitle from '../ServicesSection/ServicesTitle'
import ServicesBox from '../ServicesSection/ServicesBox'

function Services() {
  
  return (
    <div>
      <section className="services-section">
      <Container>        
        <Row>
           <Col md="12">
            <ServicesTitle/>
           </Col> 
           <Col md="12">
            <ServicesBox/>
           </Col> 
                    
        </Row>
     </Container>
     </section>
          
    </div>
  )
}

export default Services