import React from 'react'
import 'bootstrap/dist/css/bootstrap.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import './aboutText.css';
import AboutTextTitle from '../AboutTextSection/AboutTextTitle';
import AboutTextDescription from '../AboutTextSection/AboutTextDescription';
import useSWR from 'swr'
import { endpoints } from '../../../constants/global-constans';
import { fetcher } from '../../../utils/helperFunctions';

function AboutText() {
  const {data} = useSWR(endpoints.aboutPage.whatWeProvideSection,fetcher)

  const title = data && data.data.attributes.title
  const description = data && data.data.attributes.description

  return (
    <>
      <section className="about-text-section">
      <Container>        
        <Row>
           <Col md="12">
            <AboutTextTitle title={title}/>
           </Col> 
           <Col md="12">
            <AboutTextDescription description={description}/>
           </Col>           
        </Row>
     </Container>
     </section>
    </>
  )
}

export default AboutText