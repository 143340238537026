import React from 'react'
import 'bootstrap/dist/css/bootstrap.css';
import './aboutCta.css';
import parse from 'html-react-parser';

function AboutCtaDes({description}) {

  return (
    <>
      <div className="text-center">
        <p>{parse(description??'')}</p>
      </div>      
    </>
  )
}

export default AboutCtaDes