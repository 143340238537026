import React from 'react'
import Hero from '../../Component/AboutUs/AboutHero/Hero'
import AboutText from '../../Component/AboutUs/AboutTextSection/AboutText'
import StreamlineRecruitment from '../../Component/AboutUs/StreamlineRecruitment/StreamlineRecruitment'
import Whoarewe from '../../Component/AboutUs/WhoAreWe/Whoarewe'
import Fact from '../../Component/AboutUs/Facts/Fact'
import AboutTestimonial from '../../Component/AboutUs/AboutTestimonial/AboutTestimonial'
import AboutCta from '../../Component/AboutUs/AboutCta/AboutCta'

function index() {
  return (
    <>
    <Hero />
    <AboutText />
    <Whoarewe />
    <StreamlineRecruitment />   
    <AboutCta />   
    <AboutTestimonial />  
    <Fact />
    </>
  )
}

export default index