import React,{useState} from 'react'
import 'bootstrap/dist/css/bootstrap.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import './service.css';
import Box from './Box';
import { endpoints } from '../../../constants/global-constans';
import { fetcher } from '../../../utils/helperFunctions';
import useSWR from 'swr'
import parse from 'html-react-parser';

function ServicesBox() {
  const [overlayCard, setOverlayCard] = useState(null);
  const {data} = useSWR(endpoints.landingPage.servicesSection, fetcher)

  const servicesList = data?.data?.attributes?.services_lists.data

  return (
    <div>
      <Container>
        <div className="service-box-container">
          <Row>
            {servicesList && servicesList?.map((service) => 
           {
            return (
              <Box
                key={service.id}
                list={service}
                onMouseEnter={() => setOverlayCard(service)}
                onMouseLeave={() => setOverlayCard(null)}
                hide={overlayCard === service}
              />
            )}
            )}

          </Row>
          {overlayCard && (
            <div className="overlay-card centered" style={{width:"500px"}}>
              <img src={overlayCard?.attributes?.image?.data?.attributes?.url} alt="img" />
              <div style={{margin:"20px 0px"}}>
                {parse(overlayCard?.attributes?.title??'')}
              </div>
              <div style={{width:"450px", margin:"auto"}}>
                {parse(overlayCard?.attributes?.long_desc??'')}
              </div>
            </div>
          )}
        </div>
      </Container>
    </div>
  );
}
export default ServicesBox;



