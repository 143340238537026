import React from 'react'
import Header from '../../View/Header'
import Footer from '../../View/Footer'
import HeroView from '../../View/Home'
function index() {
return (
<>
   <Header />
   <HeroView />  
   <Footer/>
</>
)
}
export default index