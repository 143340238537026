import React from 'react'
import { Button, Col, Container, Modal, Row } from 'react-bootstrap'
import { Toaster, toast } from 'react-hot-toast';
import { endpoints } from '../constants/global-constans';

const CallbackModel = ({show,handleClose}) => {
   const [formData, setFormData] = React.useState({
      email: "",
      name:"",
      mobileNumber:"",
      client:true,
      hirer:false,
   });

   const handleSubmit = (e) => {
      console.log(formData)
      e.preventDefault();
      const postForm = fetch(endpoints.postCallBackRequest, {
            method: "POST",
            headers: {
               "Content-Type": "application/json",
            },
            body: JSON.stringify(formData),
      })
      .then((res) => res.json())
      .then((data) => {
         setFormData({
            email: "",
            name:"",
            mobileNumber:"",
            client:true,
            hirer:false,
         })
      })
      .catch((err) => {
         console.log(err)
      });
      
      toast.promise(postForm, {
         loading: 'Sending...',
         success: 'Your message has been sent successfully',
         error: 'Something went wrong, please try again',
      })
   }

   const handleChange = (e) => {
      setFormData({
         ...formData,
         [e.target.name]: e.target.value,
      })
   }

   const handleClient = (e) => {
      setFormData({
         ...formData,
         [e.target.name]: e.target.value.trim(),
         client: true,
         hirer: false,
      })
   }

   const handleHirer = (e) => {
      setFormData({
         ...formData,
         hirer: true,
         client: false,
      })
   }

  return (
    <Modal show={show} backdrop="static" size="lg" centered onHide={handleClose}>
    <Modal.Header closeButton>
       <Modal.Title>Request Call Back...</Modal.Title>
    </Modal.Header>
    <Modal.Body className='text-center'style={{zIndex:3}}>
       <p>Gorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu turpis molestie, dictum</p>
       <form className="mt-4">
          <Container className="py-2 px-2">
             <Row className="justify-content-center">                
                <Col md="8">
                <div className="form-group d-flex mb-2">
                   <label className="mr-2">Are you a</label>
                   <div className="form-check mx-2">
                      <label className="form-check-label" htmlFor="gridRadios1">
                      <input className="form-check-input" type="radio" name="client" id="gridRadios1" onChange={handleClient} checked={formData.client === true} />
                      Client 
                      </label>
                   </div>
                   <div className="form-check mx-2">
                      <label className="form-check-label" htmlFor="gridRadios2">
                      <input className="form-check-input" type="radio" name="hirer" id="gridRadios2" onChange={handleHirer} checked={formData.hirer}/>
                      Hirer
                      </label>
                   </div>
                </div>
                </Col> 
                <Col md="8">
                <div className="form-group mb-4">
                   <input type="text" className="form-control" required name="name" value={formData.name} placeholder="Enter your name" onChange={handleChange}/>    
                </div>
                </Col>
                <Col md="8">
                <div className="form-group mb-4">
                   <input type="email" className="form-control" required name="email" values={formData.email} placeholder="Enter your email address" onChange={handleChange}/>    
                </div>
                </Col>
                <Col md="8">
                <div className="form-group mb-4">
                  <input type="text" className="form-control" required name="mobileNumber" value={formData.mobileNumber} placeholder="Enter your mobile number" onChange={handleChange}/>
                </div>
                </Col>           
             </Row>
          </Container>
       </form>
       <Button className="gs-btn2 mt-3" onClick={handleSubmit}> Request Call Back </Button>
    </Modal.Body>
      <div >
         <Toaster 
         position='bottom-right'
         reverseOrder={false}
         toastOptions={{
            style: {
               fontSize: '14px',
            },
         }}
         />
      </div>
 </Modal>
  )
}

export default CallbackModel