import React from 'react'
import parse from 'html-react-parser';

function AboutCtaTital({title}) {
  return (
    <>
        <div className="text-center">
            <h2>{parse(title??'')}</h2>
        </div>      
    </>
  )
}

export default AboutCtaTital
