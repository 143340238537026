import React from 'react'
import 'bootstrap/dist/css/bootstrap.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import './StreamlineRecruitment.css';
import StreamlineRecruitmentTitle from '../StreamlineRecruitment/StreamlineRecruitmentTitle'
import StreamlineRecruitmentBox from '../StreamlineRecruitment/StreamlineRecruitmentBox'
import useSWR from 'swr'
import { fetcher } from '../../../utils/helperFunctions';
import { endpoints } from '../../../constants/global-constans';

function StreamlineRecruitment() {
  const {data} = useSWR(endpoints.aboutPage.solutionSection,fetcher)
  
  const title = data?.data?.attributes?.title
  const description = data?.data?.attributes?.description
  const solutions_lists = data?.data?.attributes?.solutions_lists?.data

  return (
    <>
      <section className="streamlineRecruitment-section">
      <Container>        
        <Row>
           <Col md="12" style={{width:"max-content", margin:"auto"}}>
            <StreamlineRecruitmentTitle title={title} description={description}/>
           </Col> 
           <Col md="12" style={{width:"max-content"}}>
            <StreamlineRecruitmentBox solutions={solutions_lists}/>
           </Col>           
        </Row>
     </Container>
     </section>
    </>
  )
}

export default StreamlineRecruitment