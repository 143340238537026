import React from 'react'
import 'bootstrap/dist/css/bootstrap.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import './aboutTestimonial.css';
import AboutTestimonialTital from './AboutTestimonialTital';
import AboutTestimonialSlider from './AboutTestimonialSlider';
import AboutTestimonialBtn from './AboutTestimonialBtn';
import useSWR from 'swr'
import { endpoints } from '../../../constants/global-constans';
import { fetcher } from '../../../utils/helperFunctions';

function AboutTestimonial() {
  const {data} = useSWR(endpoints.testimonialSection, fetcher)
  const title = data?.data?.attributes?.title
  const description = data?.data?.attributes?.description
  const testimonial_client_lists = data?.data?.attributes?.testimonial_client_lists?.data

  return (
    <>
      <section className="aboutTestimonial-section">
      <Container>        
        <Row>
           <Col md="12" style={{width:"max-content",margin:"auto"}}>
            <AboutTestimonialTital title={title} description={description} />
           </Col> 
           <Col md="12" style={{width:"max-content", margin:"auto"}}>
            <AboutTestimonialSlider clients={testimonial_client_lists} />
           </Col> 
           <Col md="12">
            <AboutTestimonialBtn />
           </Col>          
        </Row>
     </Container>
     </section>
    </>
  )
}

export default AboutTestimonial