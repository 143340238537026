import React from 'react'
import Hero from '../../Component/Home/HeroSection/Hero'
import About from '../../Component/Home/AboutSection/About'
import Services from '../../Component/Home/ServicesSection/Services'
import Testimonial from '../../Component/Home/TestimonialSection/Testimonial'

function index() {
  return (
    <>
    <Hero />
    <About />
    <Services />
    <Testimonial />
    </>
  )
}

export default index