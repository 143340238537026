import React from 'react'
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.css';
import './about.css';
import "react-bootstrap-carousel/dist/react-bootstrap-carousel.css";
import Carousel from 'react-bootstrap/Carousel';
import useSWR from 'swr'
import { fetcher } from '../../../utils/helperFunctions';
import { endpoints } from '../../../constants/global-constans';
import parse from 'html-react-parser';

function AboutText() {
   const {data} = useSWR(endpoints.landingPage.aboutSection, fetcher )

return (

<div>
   <div className="about-text">
      <h5>ABOUT US</h5>
      {parse(data?.data?.attributes.title??'' )}
      <Carousel slide={false}>
         <Carousel.Item>
            <Carousel.Caption>
               {parse(data?.data?.attributes.first_slide??'')}
            </Carousel.Caption>
         </Carousel.Item>
         <Carousel.Item>
            <Carousel.Caption>
            {parse(data?.data?.attributes.second_slide??'')}
            </Carousel.Caption>
         </Carousel.Item>
         <Carousel.Item>
            <Carousel.Caption>
            {parse(data?.data?.attributes.third_slide??'')}
            </Carousel.Caption>
         </Carousel.Item>
      </Carousel>
      <Link to="/home" className="about-btn">Know more</Link>
   </div>
</div>
)
}
export default AboutText