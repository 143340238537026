import React from 'react'

function copyright() {
  return (
    <div>
      <div className="copyright">
        Copyright &#169;	2021 platON, All rights reserved.
      </div>
    </div>
  )
}

export default copyright