import React from 'react'
import 'bootstrap/dist/css/bootstrap.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import './hero.css'
import useSWR from 'swr'
import { fetcher } from '../../../utils/helperFunctions';
import { endpoints } from '../../../constants/global-constans';
import { Button } from 'react-bootstrap';
import CallbackModel from '../../CallbackModel';
import parse from 'html-react-parser';

function Hero() {   
   const {data} = useSWR(endpoints.landingPage.mainHero, fetcher)
   const [show, setShow] = React.useState(false);

   const handleClose = () => setShow(false);
   const handleShow = () => setShow(true);

return (
<div>
   <div className="hero d-flex align-items-center">
      <Container className="hero-text" style={{zIndex:"1"}}>
         <Row className="justify-content-center text-center">
            <Col md="7">
            {parse(data?.data?.attributes?.subheadline??'')}
            {parse(data?.data?.attributes?.subheroTitle??'')}
               <div className="text-center mt-4">        
                  <Button variant="primary" onClick={handleShow} className="gs-btn"> Request Call Back </Button>
               </div>
            </Col>           
         </Row>
      </Container>
      {/* modal */}
      <CallbackModel show={show} handleClose={handleClose}/>
   </div>
</div>
)
}
export default Hero