import React from 'react'
import 'bootstrap/dist/css/bootstrap.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import './fact.css';
import FactTital from './FactTital';
import FactBox from './FactBox';
import FactBtn from './FactBtn';
import useSWR from 'swr'
import { endpoints } from '../../../constants/global-constans';
import { fetcher } from '../../../utils/helperFunctions';

function Fact() {
  const {data} = useSWR(endpoints.aboutPage.growthSection,fetcher)
  const title = data?.data?.attributes?.title
  const growth_cardLists = data?.data?.attributes?.growth_card_lists?.data

  return (
    <>
      <section className="fact-section">
      <Container>        
        <Row>
           <Col md="12">
            <FactTital title={title} />
           </Col> 
           <Col md="12">
            <FactBox growthLists={growth_cardLists} />
           </Col>
           <Col md="12">
            <FactBtn />
           </Col>           
        </Row>
     </Container>
     </section>
    </>
  )
}

export default Fact