import React from 'react'
import 'bootstrap/dist/css/bootstrap.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import CountUp from 'react-countup';
import parse from 'html-react-parser';

function FactBox({growthLists}) {
  return (
    <>
     <Container>        
        <Row>
          {
            growthLists?.map((item,index) => {
              return(
                <Col md="4" key={item.id}>
                <div className='fact-box'>
                    <h1><CountUp end={parse(item.attributes.count??0)} duration={1} />+</h1>
                    <p>{parse(item.attributes.title??'')}</p>
                </div>
               </Col>
              )
            }
            )
          }         
        </Row>
     </Container>
    </>
  )
}

export default FactBox
