import React from 'react'
import 'bootstrap/dist/css/bootstrap.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import './whoarewe.css';
import WhoareweTitle from '../WhoAreWe/WhoareweTitle';
import Whoarewebox from '../WhoAreWe/Whoarewebox';
import useSWR from 'swr'
import { endpoints } from '../../../constants/global-constans';
import { fetcher } from '../../../utils/helperFunctions';

function Whoarewe() {
  const {data} = useSWR(endpoints.aboutPage.whoWeAreSection,fetcher)

  const title = data?.data?.attributes?.title
  const keypoints = data?.data?.attributes?.keypoints?.data

  return (
    <>
     <section className="who-box-section">
      <Container>        
        <Row>
          <Col md="12">
            <WhoareweTitle title={title}/>
          </Col> 
          <Col md="12">
            <Whoarewebox keypoints={keypoints}/>
          </Col> 
                              
        </Row>
     </Container>
     </section>
    </>
  )
}

export default Whoarewe
