import React from 'react'
import 'bootstrap/dist/css/bootstrap.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import './testimonial.css';
import parse from 'html-react-parser';

function TestimonialText({apiData}) {
  return (
    <div>
      <Container>        
        <Row>
           <Col md="6" style={{width:"max-content",margin:"auto"}}>
            <div className="testimonial-iconbox">
            <img src='img/visit-us.png' alt='' style={{height:50}}/>
            <div style={{paddingBottom:10}}>
              {parse(apiData?.address_label??'')}
            </div>
            <div>
              {parse(apiData?.address_location??'')}
            </div>
            </div>
           </Col> 
           <Col md="6">
           <div className="testimonial-iconbox">
            <img src='img/group.png' alt='' style={{height:50}}/>
            <h5>Social Handle</h5>
            <ul style={{display:'flex', justifyContent:'center',gap:'20px'}}>
              {
                apiData?.social_handles?.data?.map(item=>{
                  return (
                    <li key={item.id} style={{listStyle:'none'}}>
                      <a href={item?.attributes?.url} target="_blank" rel="noreferrer">
                        <i className={`fa fa-${item?.attributes?.label} text-black`} style={{fontSize:20}}></i>
                      </a>
                    </li>
                  )
                })
              }
            </ul>
            </div>
           </Col>           
        </Row>
     </Container>
    </div>
  )
}

export default TestimonialText
