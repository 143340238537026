import React, { useState } from 'react'
import 'bootstrap/dist/css/bootstrap.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import './hero.css'
import useSWR from 'swr'
import { endpoints } from '../../../constants/global-constans';
import { fetcher } from '../../../utils/helperFunctions';
import { Button } from 'react-bootstrap';
import CallbackModel from '../../CallbackModel';
import parse from 'html-react-parser';

function Hero() {   
   const [show,setShow] = useState(false)
   const {data} = useSWR(endpoints.aboutPage.heroSection,fetcher)
   const title = data?.data?.attributes?.title
   const description = data?.data?.attributes?.description
   // const image = data?.data?.attributes?.image?.data?.attributes?.url

   const handleClose = () => setShow(false);
   const handleShow = () => setShow(true);

return (
<>
   <div className="about-hero d-flex align-items-center">
      <Container className="hero-text" style={{zIndex:"1"}}>
         <Row className="justify-content-center text-center">
            <Col md="9" style={{width:"max-content"}}>
               <h1 style={{fontSize:34}}>{parse(title??'')}</h1>
            <div>
               {parse(description??'')}
            </div>
            <div className="text-center mt-4">        
                  <Button variant="primary" onClick={handleShow} className="gs-btn"> Request Call Back </Button>
               </div>
            </Col>           
         </Row>
      </Container>
      {/* modal */}
      <CallbackModel show={show} handleClose={handleClose}/>
   </div>
</>
)
}
export default Hero