import React from 'react'
import { Link } from 'react-router-dom'

function FactBtn() {
  return (
    <div>
        <div className="text-center mt-4">
            <Link to="/" className="gs-btn"> Get Started </Link>
        </div>      
    </div>
  )
}

export default FactBtn
