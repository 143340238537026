import React from 'react'
import 'bootstrap/dist/css/bootstrap.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import './footer.css'
import Menu from '../Footer/FooterNav/Menu'
import Logo from '../Footer/Footerlogo/Logo'
import Newsletter from '../Footer/Newsletter/Newsletter'
import SocalIcon from '../Footer/FooterSocalIcon/SocalIcon'
import Copyright from '../Footer/FooterCopyright/Copyright'

function index() {
return (
<>
   <footer>
      <Container>
         <Row className="justify-content-center">
            <Col md="auto">
            <Newsletter/>
            </Col>
         </Row>
         <Row className="text-center footer-middle">
            <Col md="3"><Logo/></Col>
            <Col md="6"><Menu/></Col>
            <Col md="3"><SocalIcon/></Col>
         </Row>
         <Row className="justify-content-center border-top">
            <Col md="auto">
                <Copyright/>
            </Col>
         </Row>
      </Container>
   </footer>
</>
)
}
export default index