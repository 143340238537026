import React from 'react'

function Logo() {
  return (
    <div>
      <img src="img/logo.png" alt="logo" style={{height:50,width:100,objectFit:"cover"}}/>
    </div>
  )
}

export default Logo