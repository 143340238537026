import React from 'react'
import { Link } from 'react-router-dom'
import './button.css'

function Button() {

  return (
    <div>
      <Link to="/ContactUs" className="btn-contact">Contact Us</Link>
    </div>
  )
}

export default Button