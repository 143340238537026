import React from 'react'
import Contact from '../../Component/ContactUs/Contact'

function index() {
  return (
    <>
    <Contact />
    </>
  )
}

export default index