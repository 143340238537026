import { queryStringForContactSection, queryStringForSolutionSection , queryStringForNewslatterSection, queryStringForServiceSection, queryStringForPopulateAllFields, queryStringForTestimonialSection } from '../utils/helperFunctions';
export const BASE_URL = 'https://lionfish-app-b54gx.ondigitalocean.app/api'

const createEndpointUrl = (path) => `${BASE_URL}/${path}`;

export const endpoints = {
    landingPage:{
        mainHero: createEndpointUrl('mainhero'),
        aboutSection: createEndpointUrl(`landing-about?${queryStringForPopulateAllFields}`),
        servicesSection: createEndpointUrl(`landing-services-section?${queryStringForServiceSection}`),
        contactSection: createEndpointUrl(`landing-contact-section?${queryStringForContactSection}`),
    },
    aboutPage:{
        heroSection: createEndpointUrl(`about-hero-section?${queryStringForPopulateAllFields}`),
        whoWeAreSection: createEndpointUrl(`about-who-we-are?${queryStringForPopulateAllFields}`),
        whatWeProvideSection: createEndpointUrl('about-what-we-provide'),
        solutionSection: createEndpointUrl(`about-solution-section?${queryStringForSolutionSection}`),
        growthSection: createEndpointUrl(`about-growth-section?${queryStringForPopulateAllFields}`),
        callbackSection: createEndpointUrl('about-callback-section'),
    },
    testimonialSection: createEndpointUrl(`testimonial?${queryStringForTestimonialSection}`),
    newsletter: createEndpointUrl(`newslatter?${queryStringForNewslatterSection}`),
    postCallBackRequest: createEndpointUrl('callbackrequest'),
    postContactUsRequest: createEndpointUrl('contactus'),
    postNewslatterRequest: createEndpointUrl('newsletterrequest'),
}
