import { BrowserRouter, Routes, Route } from "react-router-dom";
import CookieConsent from "react-cookie-consent";
import LandingPage from "./Page/Landingpage";
import AboutUsPage from "./Page/AboutUsPage";
import ContactUs from "./Page/ContactUs";

function App() {

  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<LandingPage />}></Route>
          <Route path="/AboutUsPage" element={<AboutUsPage />}></Route>
          <Route path="/ContactUs" element={<ContactUs />}></Route>
        </Routes>

        <CookieConsent
            location="bottom"
            buttonText="I Accept!!"
            cookieName="myAwesomeCookieName2"
            style={{ background: "#2B373B" }}
            buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
            expires={150}
            enableDeclineButton
            onAccept={(acceptedByScrolling) => {
              if (acceptedByScrolling) {
                // // triggered if user scrolls past threshold
                // alert("Accept was triggered by user scrolling");
              } else {
                // alert("Accept was triggered by clicking the Accept button");
              }
            }}
        >
          This website uses cookies to enhance the user experience.{" "}
        </CookieConsent>
      </BrowserRouter>
    </div>
  );
}

export default App;
