import "bootstrap/dist/css/bootstrap.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { endpoints } from "../../../../constants/global-constans";
import { fetcher } from "../../../../utils/helperFunctions";
import useSWR from "swr";
import parse from "html-react-parser";
import { useState } from "react";
import { Toaster, toast } from "react-hot-toast";

function Newsletter() {
  const {data} = useSWR(endpoints.newsletter, fetcher)  
  const [email, setEmail] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault()
    const postForm = fetch(endpoints.postNewslatterRequest, {
      method: "POST",
      headers: {
         "Content-Type": "application/json",
      },
      body: JSON.stringify({email}),
    })
    .then((res) => {
      setEmail("")
      return res.json()})
    .catch((err) => {
      console.log(err)
    });

    toast.promise(postForm, {
      loading: 'Sending...',
      success: 'Your message has been sent successfully',
      error: 'Something went wrong, please try again',
    })

};


  
  return (
    <div className="footer-newsletter">
      <Container style={{zIndex:1}}>
        <Row className="justify-content-center">
          <Col md="7">
          <h4>{parse(data?.data?.attributes?.title??'')}</h4>
          <p>{parse(data?.data?.attributes?.description??'')}</p>
          </Col>
          <Col md="6">
            <form>
              <input type="email" name="email" placeholder="Enter your email" onChange={
                (e)=>setEmail(e.target.value)
              } value={email}/>
              <input type="submit" value=">>" onClick={handleSubmit} />
            </form>
          </Col>
        </Row>
      </Container>

      <Toaster
        position="bottom-right"
        reverseOrder={false}
        toastOptions={{
          style: {
            fontSize: "14px",
            zIndex: 1,
          },
        }}
      />
    </div>
  );
}

export default Newsletter;
