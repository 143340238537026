import React from 'react'
import { Link } from 'react-router-dom';

function Menu() {
  return (
    <div>
        <div className="footer-links">
            <ul>
              <li><Link to="#"><i className="fa fa-circle"></i> About Us</Link></li>
              <li><Link to="#"><i className="fa fa-circle"></i> Term of Use</Link></li>
              <li><Link to="#"><i className="fa fa-circle"></i> Privacy Policy</Link></li>
              <li><Link to="#"><i className="fa fa-circle"></i> Cookie Policy</Link></li>
              <li><Link to="#"><i className="fa fa-circle"></i> Help Center</Link></li>
            </ul>
        </div>
    </div>
  )
}

export default Menu