import React from 'react'
import 'bootstrap/dist/css/bootstrap.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import './whoarewe.css';
import parse from 'html-react-parser';

 function Whoarewebox({keypoints}) {
   return (
    <>
     <div className="who-box-section-bg">
     <Container>        
        <Row>
          {
            keypoints?.map((keypoint,index) => {
              return(
                <Col md="3 mb-2" key={keypoint.id}>
                  <div className="who-box">
                      {parse(keypoint?.attributes?.description??'')}
                  </div>
                </Col> 
              )
            }
            )
          }                
        </Row>
     </Container>       
     </div>
    </>
   )
 }
 
 export default Whoarewebox
 