import React from "react";
import "./service.css";
import Col from "react-bootstrap/Col";
import parse from "html-react-parser";

function Box({ list, onMouseEnter, onMouseLeave, hide }) {
  return (
    <Col
      md="4"
      className={`service-box-col${hide ? " hide" : ""}`}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      style={{ cursor: "pointer" }}
    >
      <div className="service-box">
        <img src={list?.attributes?.image?.data?.attributes?.url} alt="img" />
        <h5>{list?.attributes?.title}</h5>
        <p>{parse(list?.attributes?.short_desc)}</p>
      </div>
    </Col>
  );
}

export default Box;
