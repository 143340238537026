import React from 'react'
import 'bootstrap/dist/css/bootstrap.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import './aboutCta.css';
import AboutCtaBtn from './AboutCtaBtn';
import AboutCtaDes from './AboutCtaDes';
import AboutCtaTital from './AboutCtaTital';
import useSWR from 'swr'
import { endpoints } from '../../../constants/global-constans';
import { fetcher } from '../../../utils/helperFunctions';

function AboutCta() {
  const {data} = useSWR(endpoints.aboutPage.callbackSection,fetcher)

  const title = data?.data?.attributes?.title
  const description = data?.data?.attributes?.description

  return (
    <>
      <section className="aboutCta-section">
      <Container className="bg-white py-5 px-5 rounded">        
        <Row>
           <Col md="12" style={{width:"",margin:"auto"}}>
            <AboutCtaTital title={title} />
           </Col> 
           <Col md="12" style={{width:"max-content",margin:"auto"}}>
            <AboutCtaDes description={description} />
           </Col>
           <Col md="12">
            <AboutCtaBtn />
           </Col>           
        </Row>
     </Container>
     </section>
    </>
  )
}

export default AboutCta