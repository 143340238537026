import React from 'react'
import { Link } from 'react-router-dom'
import 'font-awesome/css/font-awesome.min.css';
import useSWR from 'swr'
import { endpoints } from '../../../../constants/global-constans';
import { fetcher } from '../../../../utils/helperFunctions';

function SocalIcon() {
  const {data} = useSWR(endpoints.newsletter, fetcher)  

  return (
    <div>
      <div className="social-links">
        {
          data?.data?.attributes?.social_handles?.data?.map((item, index) => (
            <Link to={item?.attributes?.url} key={item.id}>
                <i className={`fa fa-${item?.attributes?.label}`}></i>
              </Link>
          ))
        }
      </div>
    </div>
  )
}

export default SocalIcon