import React from 'react'
import 'bootstrap/dist/css/bootstrap.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import './testimonial.css';
import TestimonialHeading from '../TestimonialSection/TestimonialHeading'
import TestimonialSlider from '../TestimonialSection/TestimonialSlider'
import TestimonialText from '../TestimonialSection/TestimonialText'
import useSWR from 'swr'
import { endpoints } from '../../../constants/global-constans';
import { fetcher } from '../../../utils/helperFunctions';

function Testimonial() {
  const {data} = useSWR(endpoints.landingPage.contactSection, fetcher)
  return (
    <div>
     <section className="testimonial-section">
      <Container>        
        <Row>
            <TestimonialHeading apiData={data?.data?.attributes}/>
           <Col md="4">
            <TestimonialSlider team_members={data?.data?.attributes?.team_members?.data}/>
           </Col>
           <Col md="8">            
            <TestimonialText apiData={data?.data?.attributes}/>
           </Col>           
        </Row>
     </Container>
     </section>
    </div>
  )
}

export default Testimonial
