import React from 'react'
import { Link } from 'react-router-dom'

function AboutTestimonialBtn() {
  return (
    <>
        <div className="text-center mt-5">
            <Link to="/" className="view-btn"> View All</Link>
        </div>       
    </>
  )
}

export default AboutTestimonialBtn
