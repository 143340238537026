import React from 'react'
import 'bootstrap/dist/css/bootstrap.css';
import './about.css';
import useSWR from 'swr'
import { endpoints } from '../../../constants/global-constans';
import { fetcher } from '../../../utils/helperFunctions';

function AboutImg() {
  const {data} = useSWR(endpoints.landingPage.aboutSection,fetcher)

  const images = data?.data?.attributes?.images?.data

  return (
    <div>
        <div className="img-box1">
               <div className="img1"><img src={images?.[0]?.attributes?.url} alt="About"/></div>
               <div className="img2"><img src={images?.[1]?.attributes?.url} alt="About"/></div>
        </div>
    </div>
  )
}

export default AboutImg