import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import 'react-phone-input-2/lib/style.css'
import CallbackModel from '../../CallbackModel';


function AboutCtaBtn() {
const [show, setShow] = useState(false);
const handleClose = () => setShow(false);
const handleShow = () => setShow(true);

return (
<>
<div className="text-center mt-4">        
   <Button variant="primary" onClick={handleShow} className="gs-btn"> Request Call Back </Button>
</div>
      <CallbackModel show={show} handleClose={handleClose}/>
</>
)
}
export default AboutCtaBtn