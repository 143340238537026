import React from 'react'
import 'bootstrap/dist/css/bootstrap.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import './conatact.css';
import Contactform from './ContactForm';
import ContactText from './ContactText';

function Contact() {
  return (
    <>
 <section className="contact-section">
      <Container>        
        <Row>            
           <Col md="6">
            <ContactText />
           </Col> 
           <Col md="6">
            <Contactform />
           </Col>                   
        </Row>
     </Container>
     </section>
    </>
  )
}

export default Contact