import React from 'react'
import 'bootstrap/dist/css/bootstrap.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import './StreamlineRecruitment.css';
import Box from './Box';

function StreamlineRecruitmentBox({solutions}) {
return (
<div>
   <Container >
      <Row>
         {solutions?.map((solution, i) => {
         return (
         <Box key={solution.id} solution={solution?.attributes} />
         )}
         )}
      </Row>
   </Container>
</div>
)
}
export default StreamlineRecruitmentBox