import React from 'react'
import 'bootstrap/dist/css/bootstrap.css';
import './aboutText.css';
import parse from 'html-react-parser';
function AboutTextTitle({title}) {
return (
<div>
   <div className="about-text-title">
      <h2>{parse(title??'')}</h2>
   </div>
</div>
)
}
export default AboutTextTitle