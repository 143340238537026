import React from 'react'
import 'bootstrap/dist/css/bootstrap.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import './StreamlineRecruitment.css';
import parse from 'html-react-parser';

function StreamlineRecruitmentTitle({title,description}) {
return (
<div>
   <div className="streamlineRecruitment-title">
      <Container>
         <Row className="justify-content-center">
            <Col md="12">
               <h2>{parse(title??'')}</h2>
               <p>{parse(description??'')}</p>
            </Col>
         </Row>
      </Container>
   </div>
</div>
)
}
export default StreamlineRecruitmentTitle