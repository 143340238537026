import React from 'react'
import Header from '../../Component/Common/Header'

function index() {
  return (
    <>
    <Header/>
    </>    
  )
}

export default index