import React from 'react'
import parse from 'html-react-parser';

function AboutTestimonialTital({title,description}) {
  return (
    <div>
        <div className="text-center">
            <h2>{parse(title??'')}</h2>
            <p>{parse(description??'')}</p>
        </div>      
    </div>
  )
}

export default AboutTestimonialTital
