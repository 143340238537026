import React from 'react'
import 'bootstrap/dist/css/bootstrap.css';
import Nav from 'react-bootstrap/Nav';

function Menu() {
  return (
    <div>
      <Nav defaultActiveKey="/Landingpage">
            <Nav.Link href="/">Hire Talent</Nav.Link>  
            <Nav.Link href="#">Jobs</Nav.Link>  
            <Nav.Link href="/AboutUsPage">About Us</Nav.Link>    
          </Nav>
    </div>
  )
}

export default Menu