import React from 'react'
import { Toaster, toast } from 'react-hot-toast';
import { endpoints } from '../../constants/global-constans';


function Contactform() {
   const [formData, setFormData] = React.useState({
      email: "",
      firstName: "",
      lastName: "",
      message: ""
   });

   const handleSubmit = (e) => {
      e.preventDefault();
      const postForm = fetch(endpoints.postContactUsRequest, {
            method: "POST",
            headers: {
               "Content-Type": "application/json",
            },
            body: JSON.stringify(formData),
      })
      .then((res) => res.json())
      .then((data) => {
         setFormData({
            email: "",
            firstName: "",
            lastName: "",
            message: ""
         })
      })
      .catch((err) => {
         console.log(err)
      });
      
      toast.promise(postForm, {
         loading: 'Sending...',
         success: 'Your message has been sent successfully',
         error: 'Something went wrong, please try again',
      })
      
   }

   const handleChange = (e) => {
      setFormData({
         ...formData,
         [e.target.name]: e.target.value
      })
   }

return (
<>
<div className="contat-from">
   <form>
      <div className="form-group mb-4">
         <label for="exampleInputEmail1">Email</label>
         <input type="email" name="email" value={formData.email} required className="form-control" placeholder="Enter your email address" onChange={handleChange}/>
      </div>
      <div className="form-group mb-4">
         <label for="exampleInputFirstName">First Name</label>
         <input type="text" name="firstName" value={formData.firstName} required className="form-control" placeholder="Enter your first name" onChange={handleChange}/>
      </div>
      <div className="form-group mb-4">
         <label for="exampleInputLastName">Last Name</label>
         <input type="text" name="lastName" value={formData.lastName}  className="form-control" placeholder="Enter your last name" onChange={handleChange} />
      </div>
      <div className="form-group mb-4">
         <label for="exampleInputmessage">Your message</label>
         <textarea name="message" className="form-control" value={formData.message} required rows="5" placeholder="Enter your message here" onChange={handleChange}></textarea>
      </div>

      <button type="submit" className="btn-form-contact" onClick={handleSubmit}>Submit</button>
   </form>
   <div style={{paddingTop:4}}>
      <Toaster
         position='bottom-right'
      />
   </div>
</div>
</>
)
}
export default Contactform