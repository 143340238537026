import React from 'react'
import 'bootstrap/dist/css/bootstrap.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import './testimonial.css';
import parse from 'html-react-parser';

function TestimonialHeading({apiData}) {
  return (
    <div>
      <div className="testimonial-title">
      <Container>
         <Row className="justify-content-center">
            <Col md="7" style={{width:"max-content"}}>
              <div>
              {parse(apiData?.title??'')}
              </div>
              <div>
              {parse(apiData?.description??'')}
              </div>
            </Col>
         </Row>
      </Container>
   </div>
    </div>
  )
}

export default TestimonialHeading
