import React from 'react'

function Logo() {
  return (
    <div>
      <img src="img/logo_icon.png" alt="logo" style={{height:80, objectFit:"contain"}}/>
    </div>
  )
}

export default Logo