import React from 'react'
import Button from '../Header/Button/Button'
import Menu from '../Header/Nav/Menu'
import Logo from '../Header/Logo/Logo'
import 'bootstrap/dist/css/bootstrap.css';
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import './header.css'
function index() {
   return (
      <div className="bg-hader">
         <Navbar collapseOnSelect expand="lg">
            <Container fluid>
               <Navbar.Brand href="/" className="logo">
                  <Logo />
               </Navbar.Brand>

               <div>
                  <img src="img/logo_title_only.png" alt="logo title"
                     className="img-fluid"
                     style={{height:50, objectFit:"contain"}}
                  />
               </div>

               <Navbar.Toggle aria-controls="responsive-navbar-nav" />
               <Navbar.Collapse className="justify-content-end" id="responsive-navbar-nav">
                  <Menu />
                  <Button />
               </Navbar.Collapse>
            </Container>
         </Navbar>
      </div>
   )
}
export default index