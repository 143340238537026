import qs from 'qs'

export const fetcher = (...args) => fetch(...args).then(res => res.json())

export const queryStringForServiceSection = qs.stringify(
    {
        populate: [
            'services_lists',
            'services_lists.image',
        ],
    },
    {
        encodeValuesOnly: true,
    }
)

export const queryStringForContactSection = qs.stringify(
    {
        populate: [
            'team_members',
            'team_members.image',
            'social_handles',
            'social_handles.image',
        ],
    },
    {
        encodeValuesOnly: true,
    }
)

export const queryStringForNewslatterSection = qs.stringify(
    {
        populate: [
            'social_handles',
            'social_handles.image',
        ],
    },
    {
        encodeValuesOnly: true,
    }
)

export const queryStringForSolutionSection = qs.stringify(
    {
        populate: [
            'solutions_lists',
            'solutions_lists.image',
        ],
    },
    {
        encodeValuesOnly: true,
    }
)

export const queryStringForTestimonialSection = qs.stringify(
    {
        populate: [
            'testimonial_client_lists',
            'testimonial_client_lists.image',
        ],
    },
    {
        encodeValuesOnly: true,
    }
)

export const queryStringForPopulateAllFields = qs.stringify(
    {
        populate: '*',
    },
    {
        encodeValuesOnly: true,
    }
)

