import React from 'react'
import 'bootstrap/dist/css/bootstrap.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import './service.css';
import { endpoints } from '../../../constants/global-constans';
import { fetcher } from '../../../utils/helperFunctions';
import useSWR from 'swr'
import parse from 'html-react-parser';

function ServicesTitle() {
   const {data} = useSWR(endpoints.landingPage.servicesSection, fetcher)
   
   return (
<div>
   <div className="service-title">
      <Container>
         <Row className="justify-content-center">
            <Col md="7" className='text-white'>
               <div>
                  {parse(data?.data?.attributes?.title??'')}
               </div>
               <div>
                  {parse(data?.data?.attributes?.description??'')}
               </div>
            </Col>
         </Row>
      </Container>
   </div>
</div>
)
}
export default ServicesTitle