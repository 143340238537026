import React from 'react'
import 'bootstrap/dist/css/bootstrap.css';
import './aboutText.css';
import parse from 'html-react-parser';

function AboutTextDescription({description}) {
return (
<>
   <div className="about-text-title">
      <p>{parse(description??'')}</p>
   </div>
</>
)
}
export default AboutTextDescription