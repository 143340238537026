import React from 'react'
import Footer from '../../Component/Common/Footer'

function index() {
  return (
    <>
        <Footer/>
    </>
  )
}

export default index