import React from 'react'
import './StreamlineRecruitment.css';
import Col from 'react-bootstrap/Col';
import parse from 'html-react-parser';


function Box( {solution} ) {
  return (
    <>
        <Col md="4">
        <div className="streamlineRecruitment-box">
            <img src={solution?.image?.data?.attributes?.url} alt="img"/>
            <h3>{parse(solution.title??'')}</h3>
            <p>{parse(solution.description??'')}</p>
         </div>
           </Col>
      
    </>
  )
}

export default Box
