import React from 'react'
import Header from '../../View/Header'
import Footer from '../../View/Footer'
import ContactUs from '../../View/ContactUs'

function index() {
return (
<>
   <Header />
   <ContactUs />  
   <Footer/>
</>
)
}
export default index