import React from 'react'
import 'bootstrap/dist/css/bootstrap.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import './about.css';
import AboutText from '../AboutSection/AboutText'
import AboutImg from '../AboutSection/AboutImg'

function About() {
  return (
    <div>
      <section className="about-section">
      <Container>        
        <Row>
           <Col md="6">
            <AboutText/>
           </Col> 
           <Col md="6">
            <AboutImg/>
           </Col>           
        </Row>
     </Container>
     </section>
    </div>
  )
}

export default About