import React from 'react'
import parse from 'html-react-parser';

function FactTital({title}) {
  return (
    <div>
        <div className="text-center">
            <h2>{parse(title??'')}</h2>
        </div>      
    </div>
  )
}

export default FactTital
